
<template>
  <div class="booking" :class="{'loading': loading}">
    <Collection />
    <DestinationAddress />
    <Packages/>
    <DeliveryService />
    <Confirmation />
    <Actions @set-booking-loader="seBookingLoader"/>

    <div class="booking-loading-overlay">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import Collection from '@/components/booking/Collection.vue';
import DestinationAddress from '@/components/booking/DestinationAddress.vue';
import Packages from '@/components/booking/Packages.vue';
import DeliveryService from '@/components/booking/DeliveryService.vue';
import Confirmation from '@/components/booking/Confirmation.vue';
import Actions from '@/components/booking/Actions.vue';

export default {
  name: 'BookingView',
  components: {
    Collection,
    DestinationAddress,
    Packages,
    DeliveryService,
    Confirmation,
    Actions
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    seBookingLoader(loading) {
      this.loading = loading;
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/partials/booking.scss';
</style>