<template>
    <div
        id="booking_collectionAddress"
        class="box collection-address"
        :class="{ editing: editing }"
    >
        <div class="inner-wrapper">
            <div class="inner-title-wrapper">
                <h5 class="title">Collection Address</h5>
                <div
                    class="address-list-dropdown"
                    :class="{ open: addressListDropdown.open }"
                >
                    <a
                        href="#"
                        class="trigger"
                        @click.prevent="toggleAddressListDropdown()"
                        >{{ addressListDropdown.value.name }}</a
                    >
                    <div class="content">
                        <div class="inner-content-wrapper">
                            <a
                                href="#"
                                class="address"
                                @click.prevent="selectAddress(item)"
                                v-for="(item, index) in addresses"
                                :key="index"
                            >
                                {{ item.name }}
                            </a>
                            <a
                                href="#"
                                class="address"
                                @click.prevent="selectAddress('new-address')"
                            >
                                New Address
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="the-values">
                <div class="cols">
                    <div class="col contact-details">
                        <div class="inner-wrapper-1">
                            <div class="title">
                                {{ addressListDropdown.value.contactName }}
                            </div>
                            <div>
                                {{ addressListDropdown.value.contactMobile }}
                            </div>
                            <div>
                                {{ addressListDropdown.value.contactEmail }}
                            </div>
                        </div>
                    </div>
                    <div class="col address">
                        <div class="inner-wrapper-1">
                            <div class="title">
                                {{ addressListDropdown.value.name }}
                            </div>
                            <div v-if="addressListDropdown.value.countryName">
                                {{ addressListDropdown.value.primary }}
                                {{ addressListDropdown.value.secondary }}
                                {{ addressListDropdown.value.cityName }}
                                {{ addressListDropdown.value.zipCode }},
                                {{ addressListDropdown.value.countryName }}
                            </div>
                            <div v-if="addressListDropdown.value.landmark">
                                Landmark:
                                {{ addressListDropdown.value.landmark }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="the-form">
                <div class="form-outer-wrapper form-style-1" :class="formState">
                    <div class="form-wrapper">
                        <div class="form">
                            <div class="form-error" v-if="error">
                                <div class="error">{{ error }}</div>
                            </div>

                            <form
                                action=""
                                @focusout="change"
                                @input="change"
                                @submit="submit"
                                novalidate
                            >
                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.name.errorMessage,
                                        }"
                                    >
                                        <label for="name"
                                            >Name
                                            <span>e.g Home or Office</span>
                                        </label>
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                data-rules='["required"]'
                                                v-model="collectionAddress.name"
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.name.errorMessage"
                                    >
                                        {{ validation.name.errorMessage }}
                                    </p>
                                </div>

                                <div class="form-group hidden">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.country.errorMessage,
                                        }"
                                    >
                                        <label for="country">Country</label>
                                        <div class="input-field">
                                            <select
                                                name="country"
                                                id="country"
                                                placeholder="Search"
                                                ref="countryDropdownEl"
                                                data-rules='["required"]'
                                                v-model="
                                                    collectionAddress.country
                                                "
                                            ></select>
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.country.errorMessage"
                                    >
                                        {{ validation.country.errorMessage }}
                                    </p>
                                </div>

                                <div
                                    class="form-group"
                                    v-show="collectionAddress.country == 'AE'"
                                >
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.state.errorMessage,
                                        }"
                                    >
                                        <label for="state">State</label>
                                        <div class="input-field">
                                            <select
                                                name="state"
                                                id="state"
                                                placeholder="State"
                                                ref="stateDropdownEl"
                                                v-model="
                                                    collectionAddress.state
                                                "
                                            >
                                                <option
                                                    :value="state.code"
                                                    v-for="state in $store.state
                                                        .uaeStates"
                                                    :key="state.code"
                                                >
                                                    {{ state.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.state.errorMessage"
                                    >
                                        {{ validation.state.errorMessage }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.addressType
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="addressType"
                                            >Type of Address</label
                                        >
                                        <div class="input-field">
                                            <select
                                                id="addressType"
                                                name="addressType"
                                                placeholder="Address Type"
                                                ref="addressTypeDropdownEl"
                                                data-rules='["required"]'
                                                v-model="
                                                    collectionAddress.addressType
                                                "
                                            >
                                                <option value="Home">
                                                    Home
                                                </option>
                                                <option value="Office">
                                                    Office
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="
                                            validation.addressType.errorMessage
                                        "
                                    >
                                        {{
                                            validation.addressType.errorMessage
                                        }}
                                    </p>
                                </div>

                                <div class="form-sep"></div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.contactName
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="contactName"
                                            >Contact Name</label
                                        >
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="contactName"
                                                id="contactName"
                                                data-rules='["required"]'
                                                v-model="
                                                    collectionAddress.contactName
                                                "
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="
                                            validation.contactName.errorMessage
                                        "
                                    >
                                        {{
                                            validation.contactName.errorMessage
                                        }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="
                                            input-field-wrapper
                                            phone-input-field-wrapper
                                        "
                                        :class="{
                                            invalid:
                                                validation.contactMobile
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="contactMobile"
                                            >Mobile</label
                                        >
                                        <div class="input-field-inner-wrapper">
                                            <div class="dial-code">
                                                {{
                                                    collectionAddress.contactDialCode
                                                }}
                                            </div>
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    class="plain-input"
                                                    name="contactMobile"
                                                    id="contactMobile"
                                                    data-rules='["required"]'
                                                    v-model="
                                                        collectionAddress.contactMobile
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="
                                            validation.contactMobile
                                                .errorMessage
                                        "
                                    >
                                        {{
                                            validation.contactMobile
                                                .errorMessage
                                        }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.contactEmail
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="contactEmail">Email</label>
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="contactEmail"
                                                id="contactEmail"
                                                data-rules='["required"]'
                                                v-model="
                                                    collectionAddress.contactEmail
                                                "
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="
                                            validation.contactEmail.errorMessage
                                        "
                                    >
                                        {{
                                            validation.contactEmail.errorMessage
                                        }}
                                    </p>
                                </div>

                                <div class="form-sep"></div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.addressLine1
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="addressLine1"
                                            >Address Line 1</label
                                        >
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="addressLine1"
                                                id="addressLine1"
                                                data-rules='["required"]'
                                                v-model="
                                                    collectionAddress.addressLine1
                                                "
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="
                                            validation.addressLine1.errorMessage
                                        "
                                    >
                                        {{
                                            validation.addressLine1.errorMessage
                                        }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.addressLine2
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="addressLine2">
                                            Address Line 2
                                            <span>Optional</span>
                                        </label>
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="addressLine2"
                                                id="addressLine2"
                                                v-model="
                                                    collectionAddress.addressLine2
                                                "
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="
                                            validation.addressLine2.errorMessage
                                        "
                                    >
                                        {{
                                            validation.addressLine2.errorMessage
                                        }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.landmark
                                                    .errorMessage,
                                        }"
                                    >
                                        <label for="landmark"
                                            >Landmark
                                            <span>Optional</span>
                                        </label>
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="landmark"
                                                id="landmark"
                                                v-model="
                                                    collectionAddress.landmark
                                                "
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.landmark.errorMessage"
                                    >
                                        {{ validation.landmark.errorMessage }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.zipCode.errorMessage,
                                        }"
                                    >
                                        <label for="zipCode">Post Code</label>
                                        <div class="input-field">
                                            <div class="half">
                                                <input
                                                    type="text"
                                                    name="zipCode"
                                                    id="zipCode"
                                                    data-rules=""
                                                    v-model="
                                                        collectionAddress.zipCode
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.zipCode.errorMessage"
                                    >
                                        {{ validation.zipCode.errorMessage }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="input-field-wrapper"
                                        :class="{
                                            invalid:
                                                validation.city.errorMessage,
                                        }"
                                    >
                                        <label for="city">City</label>
                                        <div class="input-field">
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                data-rules='["required"]'
                                                v-model="collectionAddress.city"
                                            />
                                        </div>
                                    </div>
                                    <p
                                        class="error-message"
                                        v-if="validation.city.errorMessage"
                                    >
                                        {{ validation.city.errorMessage }}
                                    </p>
                                </div>

                                <div class="form-action-group">
                                    <button type="submit" class="">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="loading-overlay">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as TomSelect from "tom-select";
//import { mapGetters } from 'vuex';
import formValidation from "@/mixins/formValidation";

export default {
    name: "CollectionAddress",
    mixins: [formValidation],
    data() {
        return {
            addresses: [],
            showNewAddressForm: false,
            editing: false,
            addressListDropdown: {
                open: false,
                value: {},
            },
            validation: {
                inputElements: [],
                name: {},
                contactName: {},
                contactMobile: {},
                contactEmail: {},
                country: {},
                state: {},
                addressType: {},
                addressLine1: {},
                addressLine2: {},
                landmark: {},
                zipCode: {},
                city: {},
            },
            collectionAddress: {
                id: "",
                name: "",
                contactName: "",
                contactMobile: "",
                contactDialCode: "+971",
                contactEmail: "",
                country: "AE",
                state: "DXB",
                addressType: "",
                addressLine1: "",
                addressLine2: "",
                landmark: "",
                zipCode: "",
                city: "",
            },
        };
    },
    mounted() {
        this.initValidation();
        this.initAddressTypeDropdown();

        this.$store.dispatch("fetchCountries").then(() => {
            this.initCountryDropdown();
            this.tsCountryDropdown.addItem(this.collectionAddress.country);
            this.tsCountryDropdown.disable();
        });

        this.$store.dispatch("fetchUAEStates").then(() => {
            this.initStateDropdown();
        });

        this.$store.dispatch("fetchAddresses").then(() => {
            if (this.$store.state.quote.params.type == "domestic") {
                this.collectionAddress.country = "AE";
                this.collectionAddress.state =
                    this.$store.state.quote.params.from;
            } else {
                this.collectionAddress.country =
                    this.$store.state.quote.params.from;
            }

            this.filterAddresses(this.$store.state.quote.params.type);

            if (this.addresses.length == 0) {
                this.selectAddress("new-address");
            } else {
                this.selectAddress(this.addresses[0]);
                //this.$store.dispatch('addUnlockedStep', 'bookingCollection');
                //this.$store.dispatch('goToNextBookingStep');
            }

            if (typeof this.tsCountryDropdown != "undefined") {
                this.tsCountryDropdown.addItem(this.collectionAddress.country);
                this.tsCountryDropdown.disable();
            }

            this.tsStateDropdown.addItem(this.collectionAddress.state);
            if (this.$store.state.quote.params.sType == "domestic") {
                this.tsStateDropdown.disable();
            }
        });
    },
    methods: {
        initCountryDropdown() {
            const countries = JSON.parse(
                JSON.stringify(this.$store.state.countries)
            );
            const flagsBaseUrl = this.$store.state.flagsBaseUrl;

            this.tsCountryDropdown = new TomSelect(
                this.$refs.countryDropdownEl,
                {
                    valueField: "code",
                    searchField: "name",
                    options: countries,
                    maxOptions: 300,
                    render: {
                        option: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                        item: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                    },
                    items: "AE",
                }
            );

            this.$refs.countryDropdownEl
                .closest(".form-group")
                .classList.remove("hidden");
            /* this.tsCountryDropdown.on('initialize', () => {
            }) */

            this.tsCountryDropdown.on("change", (country) => {
                let sCountry = countries.find((sCountry) => {
                    return sCountry.code == country;
                });

                this.collectionAddress.contactDialCode = sCountry.dialCode;

                if (sCountry.zipCodeRequired) {
                    this.$refs.countryDropdownEl
                        .closest("form")
                        .querySelector('[name="zipCode"]')
                        .setAttribute("data-rules", '["required"]');
                } else {
                    this.$refs.countryDropdownEl
                        .closest("form")
                        .querySelector('[name="zipCode"]')
                        .setAttribute("data-rules", '');
                }
            });
        },
        initAddressTypeDropdown() {
            //{items: this.$refs.addressTypeDropdownEl.value}
            this.tsAddressTypeDropdown = new TomSelect(
                this.$refs.addressTypeDropdownEl
            );

            //this.$refs.addressTypeDropdownEl.closest('.form-group').classList.remove('hidden');
        },
        initStateDropdown() {
            this.tsStateDropdown = new TomSelect(this.$refs.stateDropdownEl);
        },
        toggleAddressListDropdown(open) {
            open =
                typeof open != "undefined"
                    ? open
                    : !this.addressListDropdown.open;
            this.addressListDropdown.open = open;
        },
        filterAddresses(type) {
            if (type == "domestic") {
                this.addresses = this.$store.getters.stateAddresses(
                    this.collectionAddress.state
                );
            } else {
                this.addresses = this.$store.getters.countryAddresses(
                    this.collectionAddress.country
                );
            }
        },
        selectAddress(address) {
            if (address == "new-address") {
                this.addressListDropdown.value = { name: "New Address" };
                this.$emit("showNewAddressForm", true);
                this.editing = true;
            } else {
                this.addressListDropdown.value = address;
                this.$emit("showNewAddressForm", false);
                this.editing = false;

                this.$store.dispatch("setBookingCollectionAddress", address.id);
                this.$store.dispatch("goToNextBookingStep");
            }

            this.toggleAddressListDropdown(false);
        },
        send() {
            this.error = null;

            // Use object.assign so its cloned and referenced is not copied after edit.
            let clonedAddress = Object.assign({}, this.collectionAddress);
            let address = {
                name: clonedAddress.name,
                contactName: clonedAddress.contactName,
                contactMobile: clonedAddress.contactMobile,
                contactDialCode: clonedAddress.contactDialCode,
                contactEmail: clonedAddress.contactEmail,
                type: clonedAddress.addressType,
                primary: clonedAddress.addressLine1,
                secondary: clonedAddress.addressLine2,
                landmark: clonedAddress.landmark,
                cityName: clonedAddress.city,
                zipCode: clonedAddress.zipCode,
                countryCode: clonedAddress.country,
                state: clonedAddress.state,
            };

            this.addNewAddress(address);
        },
        addNewAddress(address) {
            this.$store
                .dispatch("addAddress", address)
                .then((data) => {
                    this.formState = null;
                    let address = this.$store.getters.addressById(data.addedId);
                    this.filterAddresses();
                    this.selectAddress(address);
                })
                .catch((error) => {
                    this.formState = null;
                    this.error = error;
                });
        },
    },
};
</script>
