<template>
    <div class="quote-contact-form">
        <div class="intro">
            <div class="title-wrapper">
                <h3 class="title">Contact Support</h3>
            </div>
            <p>
                Please fill in the details below and our team will contact you.
            </p>
        </div>
        <div class="form-outer-wrapper form-style-1" :class="formState">
            <div class="form-wrapper">
                <div class="form">
                    <form
                        action=""
                        @focusout="change"
                        @input="change"
                        @submit="submit"
                        novalidate
                    >
                        <!-- <input type="hidden" v-model="address.id" /> -->
                        <div class="form-group">
                            <div
                                class="input-field-wrapper"
                                :class="{
                                    invalid: validation.name.errorMessage,
                                }"
                            >
                                <label for="name">Name </label>
                                <div class="input-field">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        data-rules='["required"]'
                                        v-model="name"
                                    />
                                </div>
                            </div>
                            <p
                                class="error-message"
                                v-if="validation.name.errorMessage"
                            >
                                {{ validation.name.errorMessage }}
                            </p>
                        </div>

                        <div class="form-group">
                            <div
                                class="input-field-wrapper"
                                :class="{
                                    invalid: validation.email.errorMessage,
                                }"
                            >
                                <label for="email">Email </label>
                                <div class="input-field">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        data-rules='["required"]'
                                        v-model="email"
                                    />
                                </div>
                            </div>
                            <p
                                class="error-message"
                                v-if="validation.email.errorMessage"
                            >
                                {{ validation.email.errorMessage }}
                            </p>
                        </div>

                        <div class="form-group">
                            <div
                                class="input-field-wrapper"
                                :class="{
                                    invalid: validation.mobile.errorMessage,
                                }"
                            >
                                <label for="mobile">Mobile </label>
                                <div class="input-field">
                                    <input
                                        type="tel"
                                        name="mobile"
                                        id="phone"
                                        data-rules='["required"]'
                                        v-model="mobile"
                                    />
                                </div>
                            </div>
                            <p
                                class="error-message"
                                v-if="validation.mobile.errorMessage"
                            >
                                {{ validation.mobile.errorMessage }}
                            </p>
                        </div>

                        <div class="form-error">
                            <div class="error">{{ error }}</div>
                        </div>

                        <div class="form-success">
                            <div class="success">{{ success }}</div>
                        </div>

                        <div class="form-action-group">
                            <button type="submit" class="">Send</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="loading-overlay">
                <div class="loader"></div>
            </div>
        </div>
    </div>
</template>

<script>
import formValidation from "@/mixins/formValidation";

export default {
    name: "QuoteContactForm",
    mixins: [formValidation],
    data() {
        return {
            name: "",
            email: "",
            mobile: "",
            validation: {
                name: {},
                email: {},
                mobile: {},
            },
        };
    },
    mounted() {
        this.initValidation();
        if (this.$store.getters.loggedIn) {
            this.name =
                this.$store.state.user.firstName +
                " " +
                this.$store.state.user.lastName;
            this.email = this.$store.state.user.email;
            this.mobile = this.$store.state.user.mobileNumber;
        }
    },
    methods: {
        send() {
            
            let params = Object.assign({}, this.$store.state.quote.params, {
                contactName: this.name,
                contactEmail: this.email,
                contactMobile: this.mobile,
            });

            if (!!this.$route.query.p && this.$route.query.p == "booking") {
                params = Object.assign({}, params, {
                    packages: this.$store.state.booking.data.packages,
                });
            }

            this.$store
                .dispatch("contactForQuote", params)
                .then(() => {
                    this.formState = null;
                    this.addressForm = null;
                    this.success =
                        "Thank you, We will get back to you as soon as e can";
                    //TODO: success message
                })
                .catch((error) => {
                    this.formState = null;
                    this.error = error;
                });
        },
    },
};
</script>

<style lang="scss">
.quote-contact-form {
    max-width: 580px;
    margin: 80px auto 0px;

    .intro {
        text-align: center;

        .title-wrapper {
            .title {
                display: inline-block;
                text-shadow: 0 1px 48px #8986b6;
                background-image: linear-gradient(
                    90deg,
                    rgba(93, 43, 94, 1) 0%,
                    rgba(82, 62, 134, 1) 100%
                );
                font-size: 28px;
                font-weight: bold;
                text-align: center;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            color: #5d5b82;
            font-size: 16px;
            line-height: 1.6;
        }
    }

    .form-outer-wrapper {
        .form {
            max-width: 710px;
        }
    }

    .form-style-1 {
        .form {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}
</style>
