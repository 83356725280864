<template>
  <div class="container xl">
    <Booking />
  </div>
</template>

<script>
// @ is an alias to /src
import Booking from '@/components/booking/Booking.vue';

export default {
  name: 'BookingView',
  components: {
    Booking,
  },
};
</script>

<style lang="scss">

</style>
