<template>
  <div
    id="bookingDeliveryService"
    class="booking-form-section booking-delivery-service"
    :class="{
      unlocked: $store.state.booking.unlockedSteps.includes(
        'bookingDeliveryService'
      ),
    }"
  >
    <div class="booking-step-title-wrapper">
      <h4 class="title">Delivery Service</h4>
    </div>

    <div class="content">
      <div
        id="booking_deliveryService"
        class="box delivery-service"
        :class="{ editing: editing }"
      >
        <div class="inner-wrapper">
          <div class="inner-title-wrapper">
            <h5 class="title">Delivery Service</h5>
            <div class="actions">
              <a href class="btn edit" @click.prevent="edit()">Edit</a>
            </div>
          </div>

          <div class="the-values">
            <div class="delivery-services">
              <div
                class="service"
                v-if="deliveryService"
                :class="{ expand: deliveryService.expand }"
              >
                <div class="first-row">
                  <div
                    class="logo"
                    :style="{
                      'background-image':
                        'url(' + deliveryService.companyLogoURL + ')',
                    }"
                  ></div>
                  <div class="name">
                    {{ deliveryService.companyName }}
                  </div>
                  <div
                    class="note-title"
                    :class="{
                      hide: deliveryService.noteTitle == null,
                    }"
                    @click="toggle(deliveryService)"
                  >
                    {{ deliveryService.noteTitle }}
                    <!-- <span
                      v-if="estimatedDelivery(deliveryService)"
                    >({{ estimatedDelivery(deliveryService) }})</span> -->
                  </div>
                  <div class="price">
                    <span>AED</span>
                    {{ deliveryService.price }}
                  </div>
                </div>

                <div class="note-detail">
                  <div
                    class="inner-wrapper"
                    v-html="deliveryService.noteDetail"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="the-form">
            <div class="loading" v-if="loading">
              <div class="loader"></div>
            </div>
            <div
              class="delivery-services"
              v-if="!loading"
              v-show="deliveryServices.length"
            >
              <div
                class="service"
                v-for="dService in deliveryServices"
                :key="dService.companyId"
                :class="{
                  active: dService.companyId == deliveryService.companyId,
                  expand: dService.expand,
                }"
                @click.prevent="selectDeliveryService(dService)"
              >
                <div class="first-row">
                  <div class="radio-btn-wrapper">
                    <div class="radio-btn"></div>
                  </div>

                  <div
                    class="logo"
                    :style="{
                      'background-image':
                        'url(' + dService.companyLogoURL + ')',
                    }"
                  ></div>
                  <div class="name">
                    {{ dService.companyName }}
                  </div>
                  <div
                    class="note-title"
                    :class="{
                      hide: dService.noteTitle == null,
                    }"
                    @click.stop="toggle(dService)"
                  >
                    {{ dService.noteTitle }}
                    <!-- <span v-if="estimatedDelivery(dService)">({{ estimatedDelivery(dService) }})</span> -->
                  </div>

                  <div class="price">
                    <span>AED</span>
                    {{ dService.price }}
                  </div>
                </div>
                <div class="note-detail">
                  <div class="inner-wrapper">
                    {{ dService.noteDetail }}
                  </div>
                </div>
              </div>

              <p class="error-message" v-if="error">
                {{ error }}
              </p>

              
            <div class="form-wrapper payments" v-if="showCoupon">
              <div class="form">
                <div class="form-group">
                  <label for="email">Apply coupon if you have. </label>
                  <div class="input-field">
                    <input
                      type="text"
                      name="coupon"
                      id="coupon"
                      style="
                        width: 40%;
                        padding: 12px 20px;
                        margin: 20px auto 20px;
                        display: inline-block;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        box-sizing: border-box;
                      "
                    />
                  </div>
                </div>

                <button class="btn pay" @click="applyCoupon()">
                  Apply Coupon
                </button>
                <p class="error-message" v-if="couponError">
                {{ couponError }}
              </p>

              </div>
            </div>

              <div class="actions">
                <a href="#" class="btn save" @click.prevent="save()">Save</a>
              </div>
            </div>

            <div
              class="quote-contact-wrapper"
              v-show="!deliveryServices.length"
            >
              <p>
                We can't find any quotes right now. Contact for custom quote.
              </p>
              <br />
              <br />

              <router-link class="btn save" to="/quote?p=booking"
                >Contact</router-link
              >
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formValidation from "@/mixins/formValidation";
import ajax from '@/ajax';
import getLocale from "@/mixins/getLocale";

export default {
  name: "DeliveryService",
  mixins: [formValidation,getLocale],
  data() {
    return {
      error: null,
      couponError:null,
      editing: false,
      loading: true,
      deliveryServices: [],
      deliveryService: {},
      inputCoupon: "",
      applied:0,
      showCoupon:true,
    };
    // { code: "WELCOME10" },
  },
  mounted() {
    this.getQuote();

    this.deliveryService = this.$store.state.quote.deliveryService;

    window.addEventListener("refreshBookingQuote", () => {
      this.deliveryService = {};
      this.getQuote();
    });
  },
  methods: {
    edit() {
      this.editing = true;
    },
    getQuote() {
      this.loading = true;
      this.editing = true;

      let quoteParams = this.getQuoteParams();

      this.$store
        .dispatch("getQuote", quoteParams)
        .then((deliveryServices) => {
          this.loading = false;
          this.deliveryServices = deliveryServices;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getQuoteParams() {
      let quoteParams = {
        type: this.$store.state.quote.params.type,
        packages: this.$store.state.booking.data.packages,
      };

      if (this.$store.state.booking.data.collectionAddress != null) {
        quoteParams.from = this.$store.getters.addressById(
          this.$store.state.booking.data.collectionAddress
        ).countryCode;
      } else {
        quoteParams.from = this.$store.state.quote.params.from;
      }

      if (this.$store.state.booking.data.destinationAddress != null) {
        quoteParams.to = this.$store.getters.addressById(
          this.$store.state.booking.data.destinationAddress
        ).countryCode;
      } else {
        quoteParams.to = this.$store.state.quote.params.to;
      }

      return quoteParams;
    },
    selectDeliveryService(deliveryService) {
      this.deliveryService = deliveryService;
      this.error = null;
    },
    estimatedDelivery(service) {
      let estimatedDelivery = "";
      if (service.minDays == 0 && service.maxDays == 0) {
        estimatedDelivery = null;
      } else if (service.minDays == 0 || service.maxDays == 0) {
        estimatedDelivery = service.minDays + service.maxDays + " Days";
      } else {
        estimatedDelivery = service.minDays + " - " + service.maxDays + " Days";
      }
      return estimatedDelivery;
    },
    save() {
      if (
        Object.keys(this.deliveryService).length === 0 &&
        this.deliveryService.constructor === Object
      ) {
        this.error = "Please select a delivery service";
        return;
      }

      this.$store.dispatch(
        "setBookingDeliveryService",
        this.deliveryService.companyId
      );
      this.editing = false;
      this.$store.dispatch("readyForPayment", false);
      //this.$store.dispatch("setBookingConfirmation", null);
      this.$store.dispatch("removeUnlockedStep", "bookingActions");
      this.$store.dispatch("goToNextBookingStep");
    },
    toggle(deliveryService) {
      if (deliveryService.noteTitle != null) {
        deliveryService.expand = !deliveryService.expand;
      }
    },
    applyCoupon() {
       const coupon = document.getElementById("coupon").value;
       const formData = {couponCode:coupon};
      return new Promise((resolve, reject) => {
        ajax
          .post('/coupon/get', formData)
          .then((response) => {
            let data = response.data;
            if (data.data != false) {
              if (data.data.coupon.coupon === coupon && data.data.coupon.isActive === true) {
                let percentage =data.data.coupon.discount;
                this.applied = 1;
                alert("Coupon code is applied price would be discounted.");
                this.$store.dispatch("addCoupon",coupon);
                this.$store.dispatch("addDiscount",percentage)
                this.showCoupon = false
              } else {
                if(this.applied > 0){
                  this.couponError = "Coupon Already Applied";
                  reject("Coupon Already Applied");
                }else{
                  this.couponError = "Invalid coupon";
                  reject("Invalid coupon");
                }
              }
              resolve(data.data);
            } else {
              this.couponError = data.error.message;
              reject(data.error.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
      
    }
  },
};
</script>
