<template>
    <div class="wrapper">
        <div class="loading-overlay" v-if="loading">
            <div class="loader"></div>
        </div>

        <div class="content" v-if="!loading">
            <div v-if="services.length">
                <DeliveryServices :services="services" />
            </div>
            <div v-if="!services.length"><QuoteContactForm /></div>
        </div>
    </div>
</template>

<script>
import DeliveryServices from "@/components/quote/DeliveryServices.vue";
import QuoteContactForm from "@/components/quote/QuoteContactForm.vue";

export default {
    name: "QuoteView",
    components: {
        DeliveryServices,
        QuoteContactForm,
    },
    data() {
        return {
            loading: true,
            services: [],
        };
    },
    mounted() {
        this.setParamsNGetQuote();

        window.addEventListener("refreshQuote", () => {
            setTimeout(() => {
                this.setParamsNGetQuote();
            }, 50);
        });
    },
    methods: {
        setParamsNGetQuote() {
            this.loading = true;
            let params = this.getQuoteParams();
            
            if (params != null) {
                this.$store.dispatch("setQuoteParams", params);
                this.$store
                    .dispatch("getQuote", params)
                    .then((services) => {
                        this.loading = false;
                        this.services = services;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                this.loading = false;
            }
        },
        getQuoteParams() {
            const params = this.$route.query;

            if (params.quote == "quote") {
                const sType = params.sType;
                const from = params.from;
                const to = params.to;
                const type = params.type;
                const width = params.width;
                const height = params.height;
                const length = params.length;
                const weight = params.weight;
                const value = params.value;

                let quoteParams = {
                    type: sType,
                    from: from,
                    to: to,
                    packages: [
                        {
                            type: type,
                            width: width,
                            height: height,
                            length: length,
                            weight: weight,
                            value: value,
                        },
                    ],
                };

                return quoteParams;
            }

            return null;
        },
    },
};
</script>

<style lang="scss">
</style>
