<template>
  <div id="booking_collectionTime" class="box collection-time" :class="{ editing: editing }">
    <div class="inner-wrapper">
      <div class="inner-title-wrapper">
        <h5 class="title">Collection Time</h5>
        <div class="actions">
          <a href="" class="btn edit" @click.prevent="edit()">Edit</a>
        </div>
      </div>

      <div class="the-values">
        <div class="date-time">
          <div class="date">
            <div class="date-month">{{ formattedDate.monthDate }}</div>
            <div class="year">, {{ formattedDate.year }}</div>
          </div>
          <div class="time">{{ collectionTime.time }}</div>
        </div>
      </div>

      <div class="the-form">
        <div class="form-outer-wrapper form-style-3" :class="formState">
          <div class="form-wrapper">
            <div class="form">
              <div class="form-error" v-if="error">
                <div class="error">{{ error }}</div>
              </div>
              <form action="" @focusout="change" @input="change" @submit="submit" novalidate>
                <div class="form-inner-wrapper">
                  <div class="inputs">
                    <div class="form-group">
                      <div class="input-field-wrapper" :class="{ invalid: validation.date.errorMessage }">
                        <div class="input-field date">
                          <input name="date" id="date" class="date" placeholder="Date..." type="text" ref="datePickerEl" v-model="collectionTime.date" data-rules='["required"]' />
                        </div>
                      </div>
                      <p class="error-message" v-if="validation.date.errorMessage">
                        {{ validation.date.errorMessage }}
                      </p>
                    </div>

                    <div class="form-group">
                      <div class="input-field-wrapper" :class="{ invalid: validation.time.errorMessage }">
                        <div class="input-field time">
                          <select ref="timeDropdownEl" name="time" id="time" data-rules='["required"]' v-model="collectionTime.time" placeholder="Time...">
                            <option>Morning</option>
                            <option>Afternoon</option>
                          </select>
                        </div>
                      </div>
                      <p class="error-message" v-if="validation.time.errorMessage">
                        {{ validation.time.errorMessage }}
                      </p>
                    </div>
                  </div>

                  <div class="actions">
                    <div class="form-action-group">
                      <button type="submit" class="">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="loading-overlay">
            <div class="loader"></div>
          </div>
        </div>
      </div>

      <div class="note" v-html="getLocale('bookingCollectionTimeNote')">
      </div>
    </div>
  </div>
</template>

<script>
/* global isToday */

import flatpickr from 'flatpickr';
import * as TomSelect from 'tom-select';
//import { mapGetters } from 'vuex';
import formValidation from '@/mixins/formValidation';
import getLocale from '@/mixins/getLocale';

export default {
  name: 'CollectionTime',
  mixins: [formValidation, getLocale],
  data() {
    return {
      isToday: false,
      editing: true,
      formattedDate: {
        monthDate: '',
        year: '',
      },
      validation: {
        inputElements: [],
        time: {},
        date: {},
      },
      collectionTime: {
        time: '',
        date: '',
      },
    };
  },
  mounted() {
    this.initValidation();
    this.initTimeDropdown();
    this.initDatepicker();
  },
  methods: {
    initTimeDropdown() {
      this.tsTimeDropdown = new TomSelect(this.$refs.timeDropdownEl);
      this.tsTimeDropdown.on('change', this.onChangeTime);
    },
    onChangeTime(time) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let value = time == 'Morning' ? tomorrow : today;
      this.fpDatepicker.set('minDate', value);
    },
    togglePickupTimes() {
        let today = new Date();
            
        let disableSameDayPickupMorningAfter = process.env.VUE_APP_DISABLE_SAME_DAY_PICKUP_MORNING_AFTER.split(":");
        if(today.getHours() >= parseInt(disableSameDayPickupMorningAfter[0]) && today.getMinutes() >= parseInt(disableSameDayPickupMorningAfter[1])) {
            this.tsTimeDropdown.updateOption('Morning', { value: 'Morning', text: 'Morning', disabled: this.isToday });      
        }

        let disableSameDayPickupAfternoonAfter = process.env.VUE_APP_DISABLE_SAME_DAY_PICKUP_AFTERNOON_AFTER.split(":");
        if(today.getHours() >= parseInt(disableSameDayPickupAfternoonAfter[0]) && today.getMinutes() >= parseInt(disableSameDayPickupAfternoonAfter[1])) {
            this.tsTimeDropdown.updateOption('Afternoon', { value: 'Afternoon', text: 'Afternoon', disabled: this.isToday });      
        }
    },
    initDatepicker() {
        
        let today = new Date();
        let minDate = today;
        let disableSameDayPickupAfter = process.env.VUE_APP_DISABLE_SAME_DAY_PICKUP_AFTER.split(':');
        if(today.getHours() >= parseInt(disableSameDayPickupAfter[0]) && today.getMinutes() >= parseInt(disableSameDayPickupAfter[1]))
        {
            minDate = new Date(today)
            minDate.setDate(minDate.getDate() + 1);
        }

        this.fpDatepicker = flatpickr(this.$refs.datePickerEl, {
        disable: [
          function (date) {
            // 0 is Sunday, 6 is Saturday
            return date.getDay() === 0 || date.getDay() === 6;
          },
        ],
        minDate: minDate,
        disableMobile: 'true',
        dateFormat: 'Y-m-d', //d-M-Y
        monthSelectorType: 'static',
        yearSelectorType: 'static',
        onChange: (selectedDates) => {
          //dateStr, instance
          if (isToday(selectedDates[0])) {
            this.isToday = true;
          } else {
            this.isToday = false;
          }

          this.togglePickupTimes();
        },
      });
    },
    toggleAddressListDropdown() {
      this.addressListDropdown.open = !this.addressListDropdown.open;
    },
    selectAddress(address) {
      if (address == 'new-address') {
        this.addressListDropdown.value = { name: 'New Address' };
        this.showNewAddressForm = true;
      } else {
        this.addressListDropdown.value = address;
        this.showNewAddressForm = false;
      }

      this.toggleAddressListDropdown();
    },
    edit() {
      this.editing = true;
    },
    send() {
      this.error = null;
      this.editing = false;
      this.formState = null;
      this.saveTime(this.collectionTime);
    },
    saveTime(collectionTime) {
      this.$store.dispatch('setBookingCollectionTime', collectionTime);
      this.$store.dispatch('goToNextBookingStep');
      let date = flatpickr.parseDate(collectionTime.date, 'Y-m-d');
      this.formattedDate.monthDate = flatpickr.formatDate(date, 'F j');
      this.formattedDate.year = flatpickr.formatDate(date, 'Y');
    },
  },
};
</script>
