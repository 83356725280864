<template>
  <div
    id="bookingActions"
    class="booking-form-section booking-actions"
    :class="{
      unlocked: $store.state.booking.unlockedSteps.includes('bookingActions'),
    }"
  >
    <div
      class="content"
      :class="{ payment: $store.state.booking.readyForPayment }"
    >
      <div class="booking-error" v-if="error">
        <div class="error">{{ error }}</div>
      </div>

      <div id="booking_actions" class="box actions">
        <a href="#" class="btn book" @click.prevent="book()">Book</a>
      </div>
      <div class="box payments">
        <a :href="paymentURL" class="btn pay"> <span style="text-decoration: line-through;"> {{ oldPrice }}</span>  Pay AED {{ total }}</a>
        <div class="note" v-html="getLocale('bookingActionNote')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import getLocale from "@/mixins/getLocale";
export default {
  name: "Actions",
  mixins: [getLocale],
  data() {
    return {
      error: null,
      paymentURL: null,
      total: null,
      oldPrice:'',
    
    };
  },
  mounted() {},
  created() {
    // axios
    //   .get('http://localhost/sendit-admin/public/api/v1/')
    //   .then(response => (this.info = response))
  },
  methods: {
    book() {
      //document.querySelector('.booking').classList.add('loading');
      this.$emit("setBookingLoader", true);
      this.error = null;

      let bookingData = {
        collectionDate: this.$store.state.booking.data.collectionTime.date,
        collectionTime: this.$store.state.booking.data.collectionTime.time,
        collectionAddressId: this.$store.state.booking.data.collectionAddress,
        destinationAddressId: this.$store.state.booking.data.destinationAddress,
        selectedCompanyId: this.$store.state.booking.data.deliveryService,
        packages: this.$store.state.booking.data.packages,
        coupon: this.$store.state.booking.data.coupon,
        discount: this.$store.state.booking.data.discount,
        contactForInsurance: this.$store.state.booking.data.contactForInsurance
          ? "yes"
          : "no",
        successRedirectURL: window.location.origin + "/order-confirmation",
        failureRedirectURL: window.location.origin + "/order-failure",
      };
      
      this.$store
        .dispatch("book", bookingData)
        .then((data) => {
          console.log(data.paymentURL);
          //document.querySelector('.booking').classList.remove('loading');
          this.$emit("setBookingLoader", false);
          this.paymentURL = data.paymentURL;
          if(data.oldPrice > 0){
            this.oldPrice = data.oldPrice
          }
          this.total = data.total;
          this.$store.dispatch("readyForPayment", true);
        })
        .catch((error) => {
          this.$emit("setBookingLoader", false);
          this.error = error;
        });
    },
    
  },
};
</script>
