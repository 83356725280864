<template>
    <div
        id="bookingConfirmation"
        class="booking-form-section booking-confirmation"
        :class="{
            unlocked: $store.state.booking.unlockedSteps.includes(
                'bookingConfirmation'
            ),
        }"
    >
        <div class="booking-step-title-wrapper">
            <h4 class="title">Confirmation</h4>
        </div>

        <div class="content">
            <div
                id="booking_confirmation"
                class="box confirmation"
                :class="{ editing: !$store.state.booking.data.confirmation }"
            >
                <div class="inner-wrapper">
                    <div class="inner-title-wrapper">
                        <h5
                            v-if="!$store.state.booking.data.confirmation"
                            class="title"
                        >
                            Confirmation
                        </h5>
                        <h5
                            v-if="$store.state.booking.data.confirmation"
                            class="title"
                        >
                            Confirmed
                        </h5>
                        <div class="actions">
                            <a href="" class="btn edit" @click.prevent="edit()"
                                >Edit</a
                            >
                        </div>
                    </div>

                    <div class="cols">
                        <div class="col checkbox-col">
                            <div
                                class="checkbox"
                                :class="{
                                    active: printerAgreement,
                                }"
                                @click.prevent="togglePrinterAgreement()"
                            ></div>
                        </div>
                        <div class="col">
                            <div
                                class="agreement"
                                v-html="getLocale('bookingPrinterConfirmation')"
                            ></div>
                        </div>
                    </div>

                    <div class="cols">
                        <div class="col checkbox-col">
                            <div
                                class="checkbox"
                                :class="{
                                    active: termsAgreement,
                                }"
                                @click.prevent="toggleTermsAgreement()"
                            ></div>
                        </div>
                        <div class="col">
                            <div
                                class="agreement"
                                v-html="getLocale('bookingConfirmation')"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box contact-for-insurance">
                <div class="inner-wrapper">
                    <div class="cols">
                        <div class="col">
                            <div
                                class="checkbox"
                                :class="{
                                    active: contactForInsurance,
                                }"
                                @click.prevent="toggleContactForInsurance()"
                            ></div>
                        </div>
                        <div class="col">
                            <div
                                class="agreement"
                                v-html="getLocale('bookingContactForInsurance')"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="box booking-note"
                v-html="getLocale('bookingConfirmationNote')"
            ></div>
        </div>
    </div>
</template>

<script>
import getLocale from "@/mixins/getLocale";

export default {
    name: "DeliveryService",
    mixins: [getLocale],
    data() {
        return {
            marketingSiteURL: process.env.VUE_APP_MARKETING_SITE_URL,
            termsAgreement: false,
            printerAgreement: false,
            contactForInsurance: false
        };
    },
    methods: {
        edit() {
            this.termsAgreement = false;
            this.printerAgreement = false;
            this.$store.dispatch("setBookingConfirmation", false);
            this.$store.dispatch("removeUnlockedStep", "bookingActions");
        },
        toggleTermsAgreement() {
            this.termsAgreement = !this.termsAgreement;
            this.confirm();
        },
        togglePrinterAgreement() {
            this.printerAgreement = !this.printerAgreement;
            this.confirm();
        },
        toggleContactForInsurance() 
        {
            this.contactForInsurance = !this.contactForInsurance;
            this.$store.dispatch("setBookingContactForInsurance", true);
        },
        confirm() {
            if (this.termsAgreement && this.printerAgreement) {
                this.$store.dispatch("setBookingConfirmation", true);
                this.$store.dispatch("goToNextBookingStep");
            }
        },
    },
};
</script>
