<template>
  <div class="container xl">
    <Quote />
  </div>
</template>

<script>
import Quote from '@/components/quote/Quote.vue';

export default {
  name: 'QuoteView',
  components: {
    Quote,
  }
};
</script>
