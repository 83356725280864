<template>
  <div id="bookingCollection" class="booking-form-section booking-collection" 
  :class="{ 'new-address': showNewAddressForm, 'unlocked': $store.state.booking.unlockedSteps.includes('bookingCollection') }">
    <div class="booking-step-title-wrapper">
      <h4 class="title">Collection</h4>
    </div>

    <div class="content">
      <div class="cols">
        <div class="left">
          <CollectionAddress @show-new-address-form="setShowNewAddressForm" />
        </div>
        <div class="right">
          <CollectionTime />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionAddress from '@/components/booking/CollectionAddress';
import CollectionTime from '@/components/booking/CollectionTime';

export default {
  name: 'Collection',
  components: {
    CollectionAddress,
    CollectionTime,
  },
  data() {
    return {
      showNewAddressForm: false,
    };
  },
  mounted() {
    this.$store.dispatch('resetBookingData');
  },
  methods: {
    setShowNewAddressForm(showNewAddressForm) {
      this.showNewAddressForm = showNewAddressForm;
    },
  },
};
</script>
