<template>
  <div class="title-wrapper">
    <h2 class="title">Delivery Services</h2>
  </div>

  <div class="delivery-services">
    <div
      class="service"
      :class="{ expand: service.expand }"
      v-for="service in services"
      :key="service.companyId"
    >
      <div class="first-row">
        <div class="logo" :style="{ 'background-image': 'url(' + service.companyLogoURL + ')' }"></div>
        <div class="name">{{ service.companyName }}</div>
        <div
          class="note-title"
          :class="{ 'hide':service.noteTitle == null }"
          @click="toggle(service)"
        >
          {{ service.noteTitle }}
          <!-- <span v-if="estimatedDelivery(service)"
            >({{ estimatedDelivery(service) }})</span
          >-->
        </div>
        <div class="price">
          <span>AED</span>
          {{ service.price }}
        </div>
        <div class="actions">
          <a class="btn" href="#" @click="goToBooking(service)">Send it</a>
        </div>
      </div>
      <div class="note-detail">
        <div class="inner-wrapper"  v-html="service.noteDetail"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryServices",
  props: ["services"],
  mounted() {},
  methods: {
    goToBooking(deliveryService) {
      this.$store.dispatch("setQuoteDeliveryService", deliveryService);
      this.$router.push({ path: "/booking" });
    },
    estimatedDelivery(service) {
      let estimatedDelivery = "";
      if (service.minDays == 0 && service.maxDays == 0) {
        estimatedDelivery = null;
      } else if (service.minDays == 0 || service.maxDays == 0) {
        estimatedDelivery = service.minDays + service.maxDays + " Days";
      } else {
        estimatedDelivery = service.minDays + " - " + service.maxDays + " Days";
      }
      return estimatedDelivery;
    },
    toggle(service)
    {
      if(service.noteTitle != null)
      {
        service.expand = !service.expand;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/partials/variables.scss";

.title-wrapper {
  margin: 50px 0px;

  .title {
    text-shadow: 0 1px 48px #8986b6;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      linear-gradient(265deg, #725bba 91%, #7c4283 4%);
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.service {
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  margin-bottom: 15px;

  .first-row {
    padding: 25px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .logo {
    width: 75px;
    height: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .name {
    //flex: 1;
    padding-left: 20px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: #252423;
    flex: 0 0 30%;
  }

  .estimated-delivery {
    //flex: 1;
    padding-left: 20px;
    color: #888;
  }

  .price {
    font-size: 20px;
    font-weight: 600;
    color: #252423;
    padding-left: 30px;
    white-space: nowrap;

    span {
      font-size: 16px;
    }
  }

  .actions {
    padding-left: 30px;
    text-align: right;
    .btn {
      border-radius: 27px;
      box-shadow: 0 2px 20px 0 rgba(148, 102, 0, 0.47);
      background-color: #e79f00;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      padding: 12px 22px;
      display: block;
      display: inline-block;
      white-space: nowrap;
    }
  }

  .note-title {
    color: #252423;
    font-size: 12px;
    height: 25px;
    padding-left: 40px;
    background-image: url($srcPath + "assets/ds-arrow-down.svg");
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 25px;
    flex: 1;
    cursor: pointer;

    &.hide {
      opacity: 0;
      cursor: default;
    }
  }

  .note-detail {
    background-color: #fffbf0;
    flex: 0 0 100%;
    padding: 0px 20px;
    font-size: 13px;
    max-height: 0px;
    overflow: hidden;
    transition: all 200ms ease;

    .inner-wrapper {
      max-width: 800px;
      margin: 0px auto;
      text-align: center;
      line-height: 1.54;
    }
  }

  &.expand {
    .note-title {
      background-image: url($srcPath + "assets/ds-arrow-up.svg");
    }

    .note-detail {
      max-height: 500px;
      padding: 20px;
    }
  }
}

@media (max-width: 880px) {
  .service {
    flex-wrap: wrap;

    .logo {
      order: 1;
    }

    .name {
      //flex: 1 0 60%;
      flex: 1 0 50%;
      order: 2;
    }

    .estimated-delivery {
      flex: 1 0 60%;
      padding-left: 95px;
      order: 3;
    }

    .price {
      font-size: 20px;
      //padding-left: 95px;
      //margin-top: 10px;
      //flex: 1 0 100%;
      flex: 1;
      order: 4;
    }

    .actions {
      //flex: 1 0 100%;
      flex: 1; // 1 auto;
      margin-top: 20px;
      order: 6;
    }

    .note-title {
      order: 5;
      //margin-left: 95px;
      margin-top: 20px;
      flex: 0 0 50%;
    }
  }
}

@media (max-width: 500px) {
  .service {
    .price {
      flex: 1 0 100%;
      margin-top: 20px;
      padding-left: 0px;
    }
    .note-title {
      flex: 1;
      margin-top: 10px;
    }

    .actions {
      margin-top: 10px;
    }
  }
}

@media (max-width: 450px) {
  .service {
    margin-left: -10px;
    margin-right: -10px;

    .first-row {
      padding: 25px 15px;
    }

    .note-title {
      //margin-left: 0px;
      padding-left: 30px;
    }

    .actions {
      //margin-top: 30px;
      padding-left: 0px;
    }
  }
}
</style>
